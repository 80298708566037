import * as types from './types';
import {
  login as authLogin,
  resetPassword as authResetPassword, signUp as authSignUp
} from "services/auth";

export const loginRequest = (data) => ({
  type: types.AUTH_LOGIN_REQUEST,
  data
});

export const loginSuccess = (data, response) => ({
  type: types.AUTH_LOGIN_SUCCESS,
  data,
  response
});

export const loginFailed = (data, messages) => ({
  type: types.AUTH_LOGIN_ERROR,
  data,
  messages
});

export function login(credentials) {
  return (dispatch) => {
    dispatch(loginRequest(credentials));
    return authLogin(credentials.email, credentials.password, credentials.recaptcha)
        .then(response => dispatch(loginSuccess(credentials, response)))
        .catch((error) => {
          let messages;
          if (error.response && error.response.status === 500)
            messages = ['A server error has occurred, please try again or contact support.'];
          else
            messages = error.response ? error.response.data.non_field_errors : [];
          dispatch(
              loginFailed(credentials, messages)
          )
        });
  };
}

export const signUpRequest = (data) => ({
  type: types.AUTH_SIGN_UP_REQUEST,
  data
});

export const signUpSuccess = (data, user) => ({
  type: types.AUTH_SIGN_UP_SUCCESS,
  data,
  user
});

export const signUpFailed = (data, messages) => ({
  type: types.AUTH_SIGN_UP_ERROR,
  data,
  messages
});

export function signUp(data) {
  return (dispatch) => {
    dispatch(signUpRequest(data));
    return authSignUp(data)
        .then(response => dispatch(signUpSuccess(data, response.data)))
        .catch(error => {
          let messages = error.response ? error.response.data : null;
          dispatch(signUpFailed(data, messages));
        });
  }
}

export const resetPasswordRequest = (email) => ({
  type: types.AUTH_RESET_PASSWORD_REQUEST,
  email
});

export const resetPasswordSuccess = (data, response) => ({
  type: types.AUTH_RESET_PASSWORD_SUCCESS,
  data,
  response
});

export const resetPasswordFailed = (data, messages) => ({
  type: types.AUTH_RESET_PASSWORD_ERROR,
  data,
  messages
});

export function resetPassword(data) {
  const {email} = data;
  return (dispatch) => {
    dispatch(resetPasswordRequest(email));
    return authResetPassword(email)
        .then(function (response) {
          let {data} = response;
          dispatch(resetPasswordSuccess(email, data));
        })
        .catch((error) => {
          let messages = error.response ? error.response.data.non_field_errors : [];
          dispatch(resetPasswordFailed(email, messages));
        });
  }
}

export const receiveUser = user => ({
  type: types.USER_RECEIVE,
  user
});

export const logout = () => ({
  type: types.AUTH_LOGOUT
});
