import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles/Recaptcha.scss";

const Recaptcha = ({afterCheckedRecaptcha, onExpiredRecaptcha, refRecaptcha, refreshRecaptcha}) => {

    useEffect(() => {
        if(refreshRecaptcha)
            window.grecaptcha.reset();
    }, [refreshRecaptcha]);

  return (
      <div className="recaptcha">
        <ReCAPTCHA
            ref={refRecaptcha}
            id="recaptcha"
            name="recaptcha"
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={afterCheckedRecaptcha}
            onExpired={onExpiredRecaptcha}/>
      </div>
  )
};

Recaptcha.propTypes = {
  afterCheckedRecaptcha: PropTypes.func.isRequired, // Function executed when successful recaptcha
  onExpiredRecaptcha: PropTypes.func.isRequired, // Function executed when expire recaptcha
};

export default Recaptcha;
