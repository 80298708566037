import React, {Component} from 'react';
import {Link} from "react-router-dom";
import SEO from "components/SEO/index";
import vintageAxios from "services/api/index";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";
import ResetPasswordForm from "modules/auth/scenes/ResetPassword/components/ResetPasswordForm/index";
import vintageHistory from "services/browser-history/index";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: INITIAL_STATUS,
      errors: [],
      refreshToken: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Handles the submit form
   * @param {Object} data
   */
  handleFormSubmit(data) {
    this.setState({
      status: LOADING_STATUS,
      refreshToken: false
    }, () => {
      // Taken token
      data.uuid_token = this.props.match.params.uuidToken;
      const promise = vintageAxios.post('/auth/reset-password/', data);
      promise.then(() => {
        this.setState({
          status: SUCCESSFUL_STATUS
        }, () => {
          setTimeout(() => {
            this.setState({
              status: INITIAL_STATUS,
              errors: []
            });
            // Redirect to login form
            vintageHistory.push('/auth/login');
          }, 2000);
        });
      });
      promise.catch((error) => {
        this.setState({
          status: INITIAL_STATUS,
          errors: error.response ? error.response.data.non_field_errors : [],
          refreshToken: true
        });
      });
    });
  }

  render() {
    const {status, errors, refreshToken} = this.state;

    return (
        <div className="login">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-wrapper">
                  <div className="lightbox">
                    <div className="brand">
                      <Link to="/">
                        <span className="logo"/>
                      </Link>
                    </div>
                    <div className="wrapper">
                      <div className="row">
                        <div className="col-md-6">
                          <span className="forgot-bg"/>
                        </div>
                        <div className="col-md-6">
                          <h1>Reset Password</h1>
                          <ResetPasswordForm
                              refreshToken={refreshToken}
                              onSubmit={this.handleFormSubmit}
                              status={status}
                              errors={errors}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SEO url='auth/reset-password'/>
        </div>
    )
  }
}

export default ResetPassword;