import React, {Component} from 'react';
import {Field, FormSection, reduxForm} from 'redux-form';
import classNames from "classnames/bind";
import {renderCheckbox, renderInput, renderRadio, renderCreditCardInput} from "utils/forms/renderers";
import {
  creditCardCvv,
  creditCardCvvData,
  creditCardMonth,
  creditCardMonthData,
  creditCardNumber,
  creditCardYear,
  creditCardYearData,
  onlyText,
  required
} from "utils/forms/validators";
import CreditCardInfo from "components/CreditCardInfo/index";
import "./styles/CreditCardForm.scss";
import PropTypes from 'prop-types';
import AddressFieldsContainer from "modules/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressFields/container/index";
import SelectedAddress from "modules/store/scenes/CheckoutPage/components/CheckoutShippingAddress/components/SelectedAddress/index";
import Recaptcha from "components/Recaptcha"

export class CreditCardForm extends Component {
  constructor(props) {
    super(props);

    let addresses = [...this.props.account.addresses];

    if (this.props.selectedCreditCard.billing_address) {
      const addressExists = addresses.some(address => address.id === this.props.selectedCreditCard.billing_address);
      if (!addressExists) {
        addresses.push(this.props.selectedCreditCard.billing_address_data);
      }
    }

    this.state = {
      addressListIsOpen: false,
      reCaptcha: null,
      addresses: addresses,
    };

    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.changeSelectedAddressId = addressId => this._changeSelectedAddressId.bind(this, addressId);
    this.handleByDefaultCheck = this.handleByDefaultCheck.bind(this);
    this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
  }

  componentDidMount() {
    this.props.changeFieldValue('cc_type', 1);

    if (this.hasAddresses() && this.props.changeSelectedAddressId) {
      if (this.props.selectedCreditCard && this.props.selectedCreditCard.billing_address)
        this.props.changeSelectedAddressId(this.props.selectedCreditCard.billing_address);
      else
        this.props.changeSelectedAddressId(this.state.addresses[0].id);
    }
  }

  handleCancelButtonClick() {
    this.props.closeCreditCardModal();
  }

  /**
   * Indicates if the modal is for editing existing address
   * @returns {Boolean} editMode
   */
  get editMode() {
    return this.props.selectedCreditCard && this.props.selectedCreditCard.id;
  }

  /**
   * Verify if has added addresses
   * @return {boolean}
   */
  hasAddresses() {
    return (this.state.addresses && this.state.addresses.length > 0);
  }

  _changeSelectedAddressId(addressId) {
    this.props.changeSelectedAddressId(addressId);
  }

  handleByDefaultCheck(event) {
    const {addressFormIsOpen} = this.props;

    if (addressFormIsOpen)
      this.props.handleCloseAddressForm();
    else
      this.props.handleOpenAddressForm();
  }

  handleChangeBillingAddress() {
    this.setState({addressListIsOpen: true})
  }

  /**
   * Parses credit card number to numbers only
   *
   * @param creditCardNumber
   * @return {*}
   */
  static parseCreditCardNumber(creditCardNumber) {
    return creditCardNumber.replace(/[ ]/g, "");
  };

  render() {
    const {
      isLoading, errors, addressErrors, handleSubmit, selectedCreditCard,
      addressFormIsOpen, withCancelButton, withBillingInfo, selectedAddressId, refreshToken
    } = this.props;
    const {addressListIsOpen, reCaptcha, addresses} = this.state;

    return (
        <form
            id="credit-card-form"
            className="form credit-card-form"
            onSubmit={handleSubmit}>
          {
            this.editMode && (
                <div>
                  <h2>
                    <span className="circle">1</span>
                    <span>Credit Card Info</span>
                  </h2>
                  <p>
                    <CreditCardInfo
                        creditCard={selectedCreditCard}/>
                  </p>
                  <br/>
                  <br/>
                </div>
            )
          }
          <div className="row">
            <div className={classNames({
              "col-md-12": this.editMode,
              "col-md-6": !this.editMode
            })}>
              <div className="form-group">
                <label htmlFor="full_name">Name on Card <span className="required">*</span></label>
                <Field id="full_name"
                       name="full_name"
                       component={renderInput}
                       type="text"
                       autoComplete="cc-name"
                       className="form-control"
                       placeholder="Enter your name on card"
                       validate={[required, onlyText]}/>
              </div>
            </div>
            {
              !this.editMode && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="number">Credit card number <span className="required">*</span></label>
                      <Field id="number"
                             name="number"
                             parse={value => CreditCardForm.parseCreditCardNumber(value)}
                             component={renderCreditCardInput}
                             className="form-control"
                             autoComplete={"cc-number"}
                             placeholder="Enter your credit card number"
                             validate={[required, creditCardNumber]}/>
                      {errors["non_field_errors"] && errors["non_field_errors"].map((msj, index) => (
                          <span className="help-block" key={`creditCardNumberError${index}`}>{msj}</span>
                      ))}
                    </div>
                  </div>
              )
            }
          </div>
          <div className="row">
            <div className={classNames({
              "col-md-4": this.editMode,
              "col-md-3": !this.editMode
            })}>
              <div className="form-group">
                <label htmlFor="expiration_date">Expiration Month <span className="required">*</span></label>
                <Field id="exp_mo"
                       name="exp_mo"
                       component={renderInput}
                       autoComplete={"cc-exp-month"}
                       type="number"
                       className="form-control"
                       placeholder="Ex: 12"
                       validate={[required, creditCardMonth, creditCardMonthData]}/>
              </div>
            </div>
            <div className={classNames({
              "col-md-4": this.editMode,
              "col-md-3": !this.editMode
            })}>
              <div className="form-group">
                <label htmlFor="expiration_date">Expiration Year <span className="required">*</span></label>
                <Field id="exp_yr"
                       name="exp_yr"
                       component={renderInput}
                       type="number"
                       className="form-control"
                       placeholder="Ex: 29"
                       autoComplete={"cc-exp-year"}
                       validate={[required, creditCardYear, creditCardYearData]}/>
                <Field id="cc_type"
                       name="cc_type"
                       component={renderInput}
                       type="hidden"/>
              </div>
            </div>
            <div className={classNames({
              "col-md-4": this.editMode,
              "col-md-3": !this.editMode
            })}>
              <div className="form-group">
                <label htmlFor="verification_value">CVV Code <span className="required">*</span></label>
                <Field id="verification_value"
                       name="verification_value"
                       component={renderInput}
                       autoComplete={"cc-csc"}
                       type="password"
                       className="form-control"
                       placeholder="CVV code"
                       validate={[required, creditCardCvv, creditCardCvvData]}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              {
                withBillingInfo ?
                    <div>
                      <div className="custom-checkbox">
                        <Field
                            component={renderCheckbox}
                            name="byDefault"
                            id="byDefault"/>
                      </div>
                      <label
                          className="by_default"
                          htmlFor="byDefault">
                        Use as default
                      </label>
                    </div>
                    :
                    <div>
                      <div className="custom-checkbox">
                        <Field
                            component={renderCheckbox}
                            name="byDefault"
                            id="byDefault"
                            checked={!addressFormIsOpen}
                            onChange={this.handleByDefaultCheck}/>
                      </div>
                      <label
                          className="by_default"
                          htmlFor="byDefault">
                        Use shipping address as billing address
                      </label>
                    </div>
              }
            </div>
          </div>
          {
            withBillingInfo &&
            <div>
              {
                !addressListIsOpen ?
                    <div className="billing-address">
                      <h2>Billing Address</h2>
                      {
                        this.hasAddresses() && selectedAddressId &&
                        <div>
                          <SelectedAddress
                              address={addresses.find(item => item.id === selectedAddressId)}/>
                          <div className="billing-address__change-link">
                            <button className="un-styled" onClick={this.handleChangeBillingAddress}>Change</button>
                          </div>
                        </div>
                      }
                      <hr/>
                    </div>
                    :
                    <div className="billing-info">
                      <h2>Billing Info</h2>
                      <div className="form-group credit-card-form__address-button">
                        {
                          !addressFormIsOpen && this.hasAddresses()
                              ?
                              <button
                                  onClick={this.props.handleOpenAddressForm}
                                  type="button"
                                  className="btn-custom">
                                ADD NEW ADDRESS
                              </button>
                              :
                              <button
                                  onClick={this.props.handleCloseAddressForm}
                                  type="button"
                                  className="btn-custom">
                                USE SAVED ADDRESS
                              </button>
                        }
                      </div>
                      {
                        // Show address list
                        !addressFormIsOpen && this.hasAddresses() &&
                        <div className="form-group">
                          <ul className="radio credit-card-form__address-list">
                            {
                              addresses.map(address => (
                                  <li key={`${address.id}`}>
                                    <Field
                                        name="billing_address"
                                        id={`address_${address.id}`}
                                        component={renderRadio}
                                        validate={[required]}
                                        value={address.id}
                                        checked={selectedAddressId ? selectedAddressId === address.id : false}
                                        onChange={this.changeSelectedAddressId(address.id)}/>
                                    <label htmlFor={`address_${address.id}`}>
                                      <span className="bold">
                                        {address.full_name}
                                      </span>&nbsp;
                                      <span>
                                        {address.address_1}, {address.city_name} {address.state_code} {address.zip_code}
                                        </span>
                                    </label>
                                  </li>
                              ))
                            }
                          </ul>
                        </div>
                      }
                    </div>
              }
            </div>
          }
          {
            (addressFormIsOpen || addresses.length === 0) &&
            <FormSection
                name="address">
              <AddressFieldsContainer
                  removeOptions={true}
                  initialValues={this.props.selectedAddress}
                  changeFieldValue={this.props.changeFieldValueForAddress}
                  errors={addressErrors}/>
            </FormSection>
          }
          <div className="align-right" style={{marginBottom: "15px"}}>
            <div style={{opacity: 0}}>
              <Field
                  component={renderInput}
                  name="recaptcha"
                  id="recaptcha"
                  value={reCaptcha}/>
            </div>
            <Recaptcha
                refreshRecaptcha={refreshToken}
                afterCheckedRecaptcha={token => {this.props.changeReCaptcha(token); this.setState({reCaptcha: token})}}
                onExpiredRecaptcha={result => console.log(result)}/>
          </div>
          <div className="align-right">
            <div className="form-group">
              {
                  withCancelButton &&
                  <button type="button" className="cancel" onClick={this.handleCancelButtonClick}>CANCEL</button>
              }
              {
                isLoading ? (
                    <button
                        className="btn-custom"
                        type="submit"
                        disabled={reCaptcha === null}>
                      SAVING...
                    </button>
                ) : (
                    <button
                        className="btn-custom"
                        type="submit"
                        disabled={false}>
                      SAVE
                    </button>
                )
              }
            </div>
          </div>
        </form>
    )
  }
}


CreditCardForm.defaultProps = {
  addresses: [],
  withCancelButton: true,
  withBillingInfo: true
};

CreditCardForm.propTypes = {
  addresses: PropTypes.array,
  addressFormIsOpen: PropTypes.bool.isRequired,
  handleOpenAddressForm: PropTypes.func.isRequired,
  handleCloseAddressForm: PropTypes.func.isRequired,
  changeSelectedAddressId: PropTypes.func,
  withCancelButton: PropTypes.bool,
  selectedAddressId: PropTypes.number
};

const CreditCardReduxForm = reduxForm({
  form: 'accountCreditCardForm'
})(CreditCardForm);

export default CreditCardReduxForm;
