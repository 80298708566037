import { connect } from 'react-redux';
import NewsletterSubscribeModal from "../";
import {newsletterSubscribe, closeNewsLetterModal} from "components/NewsletterSubscribe/actions";
import {change} from "redux-form";

const mapStateToProps = (state) => {
  return {
    newsletter: state.newsletter
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newsletterSubscribe: data => dispatch(newsletterSubscribe(data)),
    closeNewsLetterModal: () => dispatch(closeNewsLetterModal()),
		changeFieldValue: (field, value) => dispatch(
			change('vintageNewsletterForm', field, value)
		)
  };
};

const NewsletterSubscribeModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterSubscribeModal);

export default NewsletterSubscribeModalContainer;